/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------UI--------------- */
import MaterialLink from '@material-ui/core/Link';

/* -----------------Child components--------------- */
import BaseLink from './baseLink';

/* -----------------Component--------------- */
const Link = (props: *) => (
  <MaterialLink
    component={BaseLink}
    {...props}
  />
);

export default Link;

export {
  BaseLink,
};
