// @flow
/* -----------------Globals--------------- */
import React, { useRef } from 'react';

/* -----------------Third parties--------------- */
import clsx from 'clsx';
import 'swiper/swiper-bundle.min.css';
import Swiper from 'react-id-swiper';
import Image from 'next/image';

/* -----------------UI--------------- */
import {
  Box,
  Button,
  ButtonBase,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ChevronRight, ChevronLeft } from 'mdi-material-ui';

/* -----------------Types--------------- */
import {
  type BundleDetails,
} from 'common/store/types';

/* -----------------Utils--------------- */
import {
  getHrefAndPathForContent,
} from '../common/utils';

/* -----------------Child components--------------- */
import Link from '../common/link';

/* -----------------Styles--------------- */
const styles = theme => ({
  image: {
    width: 125,
    height: 125,
    borderRadius: '22%',
    overflow: 'hidden',
    transition: '0.2s all ease-out',
    boxShadow: theme.shadows[1],
    '&:hover': {
      filter: 'brightness(80%)',
    },
  },
  buttonBase: {
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: theme.spacing(0, 1, 0, 1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    borderRadius: 24,
  },
  buttonContainer: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    transition: '0.2s all ease-out',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.9)',
    },
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: 32,
  },
});

/* -----------------Type declarations--------------- */
type Classes = {
  image: string,
  buttonBase: string,
  button: string,
  buttonContainer: string,
  icon: string,
}

/* -----------------Props--------------- */
type Props = {
  classes: Classes,
  bundleList: BundleDetails[]
};

/* -----------------Component--------------- */
const PremiumCarouselComponent = (props: Props) => {
  const {
    classes,
    bundleList,
  } = props;

  const swiperRef = useRef(null);

  const goNext = () => {
    if (swiperRef.current !== null) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current !== null) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const ButtonLeft = () => (
    <Box
      zIndex={1}
      className={clsx('bundle-carousel-prev', classes.buttonContainer)}
      color="common.white"
      position="absolute"
      left={0}
      top={0}
      height="100%"
    >
      <Button classes={{ root: classes.buttonBase }} onClick={goPrev}>
        <ChevronLeft className={classes.icon} />
      </Button>
    </Box>
  );
  const ButtonRight = () => (
    <Box
      zIndex={1}
      className={clsx('bundle-carousel-next', classes.buttonContainer)}
      color="common.white"
      position="absolute"
      right={0}
      top={0}
      height="100%"
    >
      <Button classes={{ root: classes.buttonBase }} onClick={goNext}>
        <ChevronRight className={classes.icon} />
      </Button>
    </Box>
  );

  const params = {
    loop: true,
    grabCursor: true,
    spaceBetween: 16,
    rebuildOnUpdate: true,
    shouldSwiperUpdate: true,
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.bundle-carousel-next',
      prevEl: '.bundle-carousel-prev',
    },
    renderPrevButton: () => <ButtonLeft />,
    renderNextButton: () => <ButtonRight />,
  };

  const renderCarousel = () => (
    <Box position="relative">
      <Swiper ref={swiperRef} {...params}>
        {bundleList.map((item) => {
          const {
            bundle: {
              id, name, numericId, icon,
            },
          } = item;
          const {
            as,
            href,
          } = getHrefAndPathForContent('BUNDLE', numericId, name);

          return (
            <div key={id} className={classes.image}>
              <Link
                hrefAs={as}
                href={href}
              >
                <ButtonBase classes={{ root: classes.button }}>
                  <Image
                    width={125}
                    height={125}
                    src={icon ? icon.url : null}
                    alt={`Bundle ${name}`}
                  />
                </ButtonBase>
              </Link>
            </div>
          );
        })}
      </Swiper>
    </Box>
  );
  return (
    renderCarousel()
  );
};

export default withStyles(styles)(PremiumCarouselComponent);
