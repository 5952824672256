// @flow
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------Third parties--------------- */
import clsx from 'clsx';

/* -----------------UI--------------- */
import {
  Box,
  Container,
  Button,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* -----------------Types--------------- */
import {
  type BundleDetails,
} from 'common/store/types';

/* -----------------Child components--------------- */
import Link from '../common/link';
import PremiumCarousel from './PremiumCarousel';

/* -----------------Styles--------------- */
const styles = theme => ({
  background: {
    background: theme.palette.darkColors.dark,
    color: theme.palette.common.white,
    padding: theme.spacing(14, 2, 14, 2),
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: theme.fontSizes.h3,
    lineHeight: 1.5,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.fontSizes.h5,
    },
  },
  subtitle: {
    fontWeight: theme.fontWeights.semiBold,
  },
  cta: {
    borderRadius: 30,
    letterSpacing: 1.25,
  },
  swiper: {
    margin: '0 auto',
    maxWidth: 'calc(100vw - 16px)',
  },
});

/* -----------------Type declarations--------------- */
type Classes = {
  background: string,
  title: string,
  subtitle: string,
  cta: string,
  swiper: string,
}

/* -----------------Props--------------- */
type Props = {
  classes: Classes,
  title: string,
  description: string,
  cta: string,
  classes: Classes,
  bundleList: BundleDetails[]
};

/* -----------------Component--------------- */
const StudyPacks = (props: Props) => {
  const {
    title,
    description,
    cta,
    classes,
    bundleList,
  } = props;

  return (
    <Box bgcolor="grey.900" color="common.white" pb={12} pt={9} textAlign="center">
      <Container maxWidth="md">
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1">
          {description}
        </Typography>
      </Container>
      <Box width="100%" pt={8} pb={10} className={clsx(classes.swiper, 'dashboardStudyPacksSwiper')}>
        <PremiumCarousel
          bundleList={bundleList}
        />
      </Box>
      <Link
        href="/store"
        hrefAs="/store"
        underline="none"
      >
        <Button
          color="secondary"
          variant="contained"
          size="large"
          className={classes.cta}
        >
          {cta}
        </Button>
      </Link>

    </Box>
  );
};

export default withStyles(styles)(StudyPacks);
