// @flow

/* -----------------Globals--------------- */
import React from 'react';

/* -----------------UI--------------- */
import {
  Box,
  Container,
  Grid,
  Typography,
  Fab,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* -----------------Third parties--------------- */
import clsx from 'clsx';
/* -----------------Analytics--------------- */
import { trackEvent } from '../../analytics/tracking';

/* -----------------Styles--------------- */

const styles = theme => ({
  gradient: {
    background: 'linear-gradient(0deg, rgba(28, 118, 203, 1) 0%, rgba(16, 68, 117, 1))',
    padding: theme.spacing(10, 2, 10, 2),
    color: theme.palette.common.white,
    position: 'relative',
    clipPath: 'polygon(0% 0%, 100% 0, 100% calc(100% - 72px), 50% 100%, 0 calc(100% - 72px))',
  },
  background: {
    backgroundImage: 'url(../../../static/splash/bg_1.jpg)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundSize: 'cover',
    opacity: 0.07,
  },
  title: {
    marginBottom: theme.spacing(4),
    lineHeight: '1.5em',
    maxWidth: 768,
    fontSize: theme.fontSizes.h2,
    textShadow: '2px 2px #000',
    textAlign: 'center',
  },
  subtitle: {
    fontWeight: theme.fontWeights.semiBold,
    fontSize: 18,
    maxWidth: 768,
    display: 'block',
    textAlign: 'center',
    textShadow: '1px 1px #000',
  },
  cta: {
    height: 72,
    fontWeight: 'bold',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(4),
    borderWidth: 2,
    borderColor: theme.palette.primary.dark,
    borderStyle: 'solid',
    borderRadius: 36,
    fontSize: 18,
    letterSpacing: 1.25,
    boxShadow: '0px 3px 5px -1px rgba(255,255,255,0.2), 0px 6px 10px 0px rgba(255,255,255,0.14), 0px 1px 18px 0px rgba(255,255,255,0.12)',
    '&:hover': {
      background: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
  },
  image: {
    height: 288,
  },
});

/* -----------------Type declarations--------------- */
type Classes = {
    gradient: string,
    background: string,
    title: string,
    subtitle: string,
    cta: string,
    image: string,
}

/* -----------------Props--------------- */
type Props = {
  title: string,
  description: string,
  cta: string,
  handleClick: *,
  classes: Classes,
};

/* -----------------Component--------------- */
const Hero = (props: Props) => {
  const {
    title,
    description,
    cta,
    handleClick,
    classes,
  } = props;

  // Start experimental code
  // const experimentName = '[M3-685]';
  // const ctas = {
  //   A: 'JOIN FOR FREE',
  //   B: 'CREATE FREE ACCOUNT',
  //   C: 'START LEARNING',
  //   D: 'STUDY FOR FREE',
  // };
  // const [expCohort, setExpCohort] = useState('A');
  // const [cta, setCta] = useState(ctas[expCohort]);

  // useEffect(() => {
  //   const runExperiment = async () => {
  //     const cohort = await getCohort({ numVariants: 4 });
  //     trackExperiment({ experimentId: experimentName, cohort });
  //     setExpCohort(cohort);
  //     setCta(ctas[cohort]);
  //   };
  //   runExperiment();
  // });

  const handleOpenDialog = () => {
    // TODO: Track click event here
    handleClick();
    trackEvent('splash hero fab clicked');
    // trackEvent('splash hero fab clicked', {
    //   'Experiment name': experimentName,
    //   'Variant name': expCohort,
    //   'Call to action': cta,
    // });
  };

  return (
    <Box textAlign="center" bgcolor="grey.900">
      <div className={classes.gradient}>
        <div className={classes.background} />
        <Container maxWidth="md">
          <Grid
            className="container"
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h1" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="h6" className={classes.subtitle}>
              {description}
            </Typography>
            <Fab
              className={clsx(classes.cta, 'splashHeroFab')}
              color="primary"
              size="large"
              variant="extended"
              onClick={handleOpenDialog}
            >
              {cta}
            </Fab>
          </Grid>
        </Container>

      </div>
    </Box>
  );
};

export default withStyles(styles)(Hero);
