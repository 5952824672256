// @flow
/* -----------------Globals--------------- */
import React, { useState } from 'react';

/* -----------------Constants--------------- */
import {
  hero,
  features,
  testimonials,
  mobileApps,
  content,
  studyPacks,
  schedule,
  // community,
} from './utils';
import dynamic from 'next/dynamic';
/* -----------------Child components--------------- */
import Hero from './Hero';
import Schedule from './Schedule';
import StudyPacks from './StudyPacks';
import Features from './Features';
import Testimonials from './Testimonials';
import MobileApps from './MobileApps';
// import Community from './Community';
import RecommendedContent from './RecommendedContent';

/* -----------------Types--------------- */
import {
  type BundleDetails,
} from 'common/store/types';

type Props = {
  bundleList: BundleDetails[]
}

const DialogAuth = dynamic(() => import('../auth/DialogAuth'));

/* -----------------Component--------------- */
const Splash = (props: Props) => {
  const {
    bundleList,
  } = props;
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClick = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const { title, description, cta } = hero;
  // TODO: Update splash hero style
  return (
    <>
      <Hero
        title={title}
        description={description}
        cta={cta}
        handleClick={handleClick}
      />
      <StudyPacks
        bundleList={bundleList}
        {...studyPacks}
      />
      <Schedule {...schedule} />
      <Features features={features} />
      <MobileApps {...mobileApps} />
      <Testimonials {...testimonials} />
      <RecommendedContent
        {...content}
        handleClick={handleClick}
      />
      {dialogOpen ? (
        <DialogAuth
          handleClose={handleClose}
          initialView="register"
          open={dialogOpen}
        />
      ) : null}
    </>
  );
};

export default Splash;
