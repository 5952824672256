// @flow

/* -----------------Globals--------------- */
import React from 'react';

/* -----------------UI--------------- */
import {
  Fab,
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardContent,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* -----------------Child components--------------- */
import Link from '../common/link';

/* -----------------Styles--------------- */

const styles = theme => ({
  cardText: {},
  icon: {
    '& > svg': {
      fontSize: 48,
      color: theme.palette.secondary.main,
      transition: '0.2s color ease-out',
    },
  },
  card: {
    transition: '0.2s all ease-out',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'transparent',
    height: '100%',
    '&:hover': {
      backgroundColor: 'transparent',
      transition: '0.2s all ease-out',
      borderColor: theme.palette.common.white,
      color: theme.palette.common.white,
      '& $cardText': {
        color: theme.palette.common.white,
      },
      '& svg': {
        color: theme.palette.common.white,
        transition: '0.2s color ease-out',
      },
    },
  },
  gradient: {
    background: '#0E3C68',
    position: 'relative',
  },
  title: {
    fontSize: theme.fontSizes.h3,
    lineHeight: 1.5,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.fontSizes.h5,
    },
  },
  avatar: {
    width: 56,
    height: 56,
    borderRadius: '50%',
    marginBottom: theme.spacing(1.5),
  },
  username: {
    color: theme.palette.secondary.light,
    fontSize: theme.fontSizes.subtitle1,
    textDecoration: 'none',
    transition: '0.2s color ease-out',
    width: '100%',
    maxWidth: 'calc(100px - 16px)',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  buttonAvatar: {
    borderRadius: theme.shape.borderRadius,
    transition: '0.2s all ease-out',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.12)',
    },
  },
  cta: {
    height: 72,
    fontWeight: 'bold',
    marginTop: theme.spacing(12),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderWidth: 2,
    borderColor: theme.palette.primary.dark,
    borderStyle: 'solid',
    borderRadius: 36,
    fontSize: 18,
    letterSpacing: 1.25,
    boxShadow: '0px 3px 5px -1px rgba(255,255,255,0.2), 0px 6px 10px 0px rgba(255,255,255,0.14), 0px 1px 18px 0px rgba(255,255,255,0.12)',
    '&:hover': {
      background: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
  },
});

/* -----------------Type declarations--------------- */
type Classes = {
  gradient: string,
  title: string,
  avatar: string,
  username: string,
  buttonAvatar: string,
  cta: string,
  card: string,
  cardText: string,
  icon: string
}

/* -----------------Props--------------- */
type Props = {
  classes: Classes,
  title: string,
  cta: string,
  contents: *,
  description: string,
  handleClick: () => void,
};

/* -----------------Component--------------- */
const Community = (props: Props) => {
  const {
    title,
    description,
    cta,
    contents,
    handleClick,
    classes,
  } = props;

  const handleOpenDialog = () => {
    // TODO: Track click event here
    handleClick();
  };

  const renderContent = (item) => {
    const {
      id,
      title: itemTitle,
      count,
      icon,
      type,
    } = item;

    const getPath = (contentType) => {
      switch (contentType) {
        case 'studySet':
          return 'flashcards';
        case 'questionSet':
          return 'questions';
        default:
          return 'flashcards';
      }
    };
    const getItemType = (contentType) => {
      switch (contentType) {
        case 'studySet':
          return 'flashcards';
        case 'questionSet':
          return 'questions';
        default:
          return 'items';
      }
    };
    // TODO: Get path correctly
    const path = `/${getPath(type)}/${id}/`;

    const itemType = getItemType(type);

    return (
      <Grid item xs={6} sm={4} md={3}>
        <Card classes={{ root: classes.card }}>
          <Link
            href={path}
            hrefAs={path}
            underline="none"
          >
            <CardActionArea>
              <CardContent>
                <div className={classes.icon}>
                  {icon}
                </div>
                <Typography
                  variant="body1"
                  align="center"
                  textAlign="center"
                  className={classes.cardText}
                >
                  {itemTitle}
                </Typography>
                <Typography
                  variant="overline"
                  align="center"
                  textAlign="center"
                  className={classes.cardText}
                >
                  {`${count} ${itemType}`}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>
      </Grid>
    );
  };

  return (
    <Box
      className={classes.gradient}
      py={14}
      color="common.white"
      textAlign="center"
    >
      <Container maxWidth="md">
        <Box mb={8}>
          <Typography
            className={classes.title}
            variant="h2"
          >
            {title}
          </Typography>
          <Typography variant="body1">
            {description}
          </Typography>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Grid
          container
          justifyContent="center"
          spacing={1}
        >
          {contents.map(item => renderContent(item))}
        </Grid>
        <Fab
          className={classes.cta}
          color="primary"
          size="large"
          variant="extended"
          onClick={handleOpenDialog}
        >
          {cta}
        </Fab>
      </Container>
    </Box>
  );
};

export default withStyles(styles)(Community);
