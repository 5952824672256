// @flow
import React from 'react';
/* -----------------Assets--------------- */
import {
  DeathStarVariant,
  ArmFlexOutline,
  Biohazard,
  Needle,
  Paw,
  ZodiacScorpio,
  PokemonGo,
  PaletteOutline,
} from 'mdi-material-ui';

/* -----------------Icons--------------- */
import Calendar from '@images/illustrations/calendar.svg';
import Notes from '@images/illustrations/notes.svg';
import Studying from '@images/illustrations/studying.svg';

/* -----------------Constants--------------- */
const hero = {
  // title: 'Put your studying on autopilot ✈️',
  // description: 'The ultimate learning tool for busy students in higher
  // education, built by MIT engineers to automate mastering anything from
  // complex medical board exams to simple vocabulary lists',
  // title: 'The ultimate learning tool \n for busy students',
  // description: 'Study for FREE with community flashcards and practice questions,
  // create your own, or upgrade your knowledge with professional Study-Packs!',
  // title: 'The ultimate learning tool for serious learners who can\'t afford not to pass',
  // title: 'The ultimate learning tool for serious students and their high-stakes exams',
  // title: 'The ultimate learning tool for conquering high-stakes exams',
  title: 'Study Less. Learn More. Score Higher.',
  // title: 'The ultimate learning tool to conquer difficult exams',
  description: 'AI-powered study tools guaranteed to help aspiring professionals in medicine and higher education master and retain any topic. Ace exams with premium Study-Packs and practice questions, create flashcards for free, and collaborate with a global community of learners!',
  cta: 'START LEARNING',
};

const studyPacks = {
  title: 'Ace your exams with premium Study-Packs',
  description: 'Save time and score higher with thousands of pre-made flashcards, practice questions, and study games. Memorang partners with leading publishers, universities, and authors to bring you trusted and regularly updated content.',
  cta: 'See all Study-Packs',
};

const schedule = {
  title: 'Save time with study schedules',
  description: 'What if you could put your studying on **autopilot?** Memorang is powered by *AIBL* which uses **spaced repetition** to give you the right things to review at *just* the right time.',
};

const features = [
  {
    title: 'Learn Anything',
    subtitle: 'Intelligent flashcards, practice questions, and study games',
    image: Studying,
  },
  {
    title: 'Create & Annotate',
    subtitle: 'Build flashcards, collaborate, and browse free community content',
    image: Notes,
  },
  {
    title: 'Save Time',
    subtitle: 'Personalized study schedules powered by spaced repetition',
    image: Calendar,
  },
  // {
  //   title: 'Study Everywhere',
  //   subtitle: 'Web, iOS, and Android apps are always synced in real-time',
  //   image: Devices,
  // },
];

const testimonials = {
  title: 'Trusted by students in higher education worldwide',
  reviews: [
    'Memorang is a game changer. Learning new information is never easy, but Memorang\'s flashcards -- and their mobile app -- make it incredibly achievable',
    'I take my studying very seriously and this is app is like having my own tutor',
    'Fast, easy to use, and a great use of free time to strengthen up on some weak points!',
    'I love the multiple choice feature. Everything feels really personalized...like I\'m being coached',
    'I\'ve found that Memorang and its mobile app are the best study tools I\'ve ever used. I\'m studying faster and more efficiently than ever before, and my retention is off the charts.',
    'This is SUCH a great product. I used it endlessly second year of med school. It\'s helpful for classes as well as the boards',
    'Memorang is probably one of the best tools for learning/memorizing facts quickly',
    'The content is definitely solid. but the program itself is a game changer. As far spaced repetition goes, your software currently has no equal that I know of',
    'Hands down amazing! Memorang is by far the best tool out there for memorization and study aid. Easy to use, great on the go for busy schedules',
    'I have to tell you that I love the app. My only regret was waiting so long to give it a try',
    'Memorang is a great way to learn a lot of information and remain interested throughout',
    'Memorang has become a very essential cornerstone in my preparation for Step 1. Its high yield integrative approach helps make concepts easier to understand and memorize',
  ],
};

const mobileApps = {
  title: 'Study anywhere on any device! Download the free apps',
  screenshots: [
    // TODO: Replace with actual screenshots
    { type: 'iOS', image: '/static/splash/splash_iphone.png' },
    { type: 'android', image: '/static/splash/splash_android.png' },
  ],
};

// TODO: Pass real data here
const content = {
  title: 'Master any subject',
  description: 'Memorang is a powerful learning platform that works with almost any subject - try some fun and free topics to test it out',
  contents: [
    {
      id: 285566,
      title: 'Anatomy: Shoulder Muscles',
      icon: <ArmFlexOutline />,
      count: 19,
      type: 'studySet',
    },
    {
      id: 285567,
      title: 'Epidemic Pathogens',
      icon: <Biohazard />,
      count: 45,
      type: 'studySet',
    },
    {
      id: 285632,
      title: 'The Mandalorian: Key Characters',
      icon: <DeathStarVariant />,
      count: 46,
      type: 'studySet',
    },
    {
      id: 285563,
      title: 'Drugs of Abuse: Street Names',
      icon: <Needle />,
      count: 197,
      type: 'studySet',
    },
    {
      id: 285436,
      title: 'Astrology Zodiac Signs',
      icon: <ZodiacScorpio />,
      count: 48,
      type: 'studySet',
    },
    {
      id: 285462,
      title: 'Famous Paintings',
      icon: <PaletteOutline />,
      count: 32,
      type: 'studySet',
    },
    {
      id: 285463,
      title: 'Exotic cats',
      icon: <Paw />,
      count: 15,
      type: 'studySet',
    },
    {
      id: 285464,
      title: 'Pokemon Go',
      icon: <PokemonGo />,
      count: 151,
      type: 'studySet',
    },
  ],
  cta: 'Join for free',
};

export {
  hero,
  features,
  studyPacks,
  testimonials,
  mobileApps,
  content,
  schedule,
};
