// @flow
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------Third parties--------------- */
import clsx from 'clsx';

/* -----------------Nextjs--------------- */
import NextLink from 'next/link';

/* -----------------UI--------------- */
import withStyles from '@material-ui/core/styles/withStyles';

/* -----------------Styles--------------- */
const styles = () => ({
  childrenStyle: {
    textDecoration: 'none',
    color: 'inherit',
  },
});

/* -----------------Type declarations--------------- */
type Classes = {
  childrenStyle: string
}

/* -----------------Props--------------- */
type Props = {
  className: string,
  href?: string,
  hrefAs?: *,
  children: *,
  replace?: boolean,
  prefetch?: boolean,
  target?: string,
  classes: Classes
};

/* -----------------Component--------------- */
const BaseLink = React.forwardRef<Props, *>((
  props: Props,
  ref: *,
) => {
  const {
    className,
    href = undefined,
    hrefAs = undefined,
    prefetch = true,
    target = '_self',
    children,
    classes,
    replace,
  } = props;

  return (
    <NextLink
      href={hrefAs || href}
      ref={ref}
      passHref
      prefetch={prefetch}
      replace={replace}
      shallow={replace}
    >
      <a
        className={
          clsx(
            className,
            classes.childrenStyle,
          )}
        ref={ref}
        target={target}
      >
        {children}
      </a>
    </NextLink>
  );
});

export default withStyles(styles)(BaseLink);
