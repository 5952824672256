// @flow
const APP_STORE_LINK = 'https://apps.apple.com/us/app/memorang-flashcards-quizzes/id916615301';
const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.memorang';
const FACEBOOK_LINK = 'https://www.facebook.com/memorangapp';
const INSTAGRAM_LINK = 'https://www.instagram.com/memorang';
const TWITTER_LINK = 'https://www.twitter.com/memorangapp';
const LEGAL_LINK = 'https://memorang.com/legal';

export {
  APP_STORE_LINK,
  GOOGLE_PLAY_LINK,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  TWITTER_LINK,
  LEGAL_LINK,
};
