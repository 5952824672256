// @flow
/* -----------------Globals--------------- */
import React, { useState, useEffect } from 'react';

/* -----------------UI--------------- */
import {
  Typography,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Chip,
  makeStyles,
} from '@material-ui/core';

/* -----------------Icons--------------- */
import {
  CalendarClock,
  ChartTimelineVariantShimmer,
  FormatListCheckbox,
  FormatListText,
  CalendarCheckOutline,
  TimerSand,
  ClockAlertOutline,
  CheckBold,
} from 'mdi-material-ui';
import { StudySet, QuestionSet } from '@components/icons';

/* -----------------Child Components--------------- */
import ProgressRing from '@components/common/ProgressRing';

/* -----------------Assets--------------- */
const getGoalDate = () => {
  const currentDate = new Date();
  const newDate = new Date(currentDate.setMonth(currentDate.getMonth() + 2));
  return newDate.toLocaleDateString();
};

const scheduleSteps = [
  {
    name: 'content',
    icon: <FormatListText />,
    title: 'Learning Content',
    description: 'What would you like to learn?',
    right: '5,000 FACTS',
  },
  {
    name: 'date',
    icon: <CalendarCheckOutline />,
    title: 'Goal Date',
    description: 'When would you like to learn it by?',
    right: getGoalDate(),
  },
  {
    name: 'sessionDuration',
    icon: <TimerSand />,
    title: 'Study Duration',
    description: 'How much will you study per day?',
    right: '45 MINUTES',
  },
  {
    name: 'reminder',
    icon: <ClockAlertOutline />,
    title: 'Daily Reminder',
    description: 'When will you typically study?',
    right: '5:00 PM',
  },
];

const useStylesListItemSchedule = makeStyles(() => ({
  listItemRoot: {
    height: 64,
  },
}));

const ListItemSchedule = (listItemScheduleProps) => {
  const {
    title, icon, right, index,
  } = listItemScheduleProps;
  const classes = useStylesListItemSchedule();
  return (
    <ListItem
      key={title}
      classes={{ root: classes.listItemRoot }}
      divider={index < scheduleSteps.length - 1}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
      <Chip label={right} />
    </ListItem>
  );
};

const SlideCreateSchedule = () => scheduleSteps.map<any, any>((step, index) => (
  <ListItemSchedule {...step} key={index.toString()} index={index} />
));

const tasks = [
  {
    type: 'Questions',
    total: 30,
    completed: 0,
    icon: <QuestionSet />,
  },
  {
    type: 'Flashcards',
    total: 100,
    completed: 0,
    icon: <StudySet />,
  },
];

const useStylesListItem = makeStyles(theme => ({
  taskTitle: {
    fontSize: theme.fontSizes.subtitle1,
    fontWeight: theme.fontWeights.semiBold,
    marginBottom: 6,
  },
  overline: {
    ...theme.overrides.MuiTypography.overline,
  },
}));

const ListItemTask = (taskProps) => {
  const {
    type, total, completed, icon, index,
  } = taskProps;
  const classes = useStylesListItem();
  const [numCompleted, setNumCompleted] = useState(completed);
  const taskComplete = total === numCompleted;
  const handleIncrementTask = () => {
    setNumCompleted(Math.min(numCompleted + 1, total));
  };
  useEffect(() => {
    const interval = setInterval(() => {
      handleIncrementTask();
    }, 20);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numCompleted]);

  return (
    <ListItem key={type} divider={index !== tasks.length - 1}>
      <Box mr="12px">
        <ProgressRing
          distribution={[numCompleted, total - numCompleted]}
          variant="progress"
          radius={24}
          innerRadius={19}
          icon={taskComplete ? <CheckBold /> : icon}
          taskComplete={taskComplete}
        />
      </Box>
      <ListItemText
        disableTypography
        primary={(
          <>
            <Typography variant="overline" className={classes.overline}>
              {`${numCompleted} / ${total}`}
            </Typography>
            <Typography className={classes.taskTitle} variant="h1">
              {type}
            </Typography>
          </>
        )}
      />
      <Button
        variant="contained"
        color="secondary"
        disabled={taskComplete}
      >
        {taskComplete ? 'DONE' : 'STUDY'}
      </Button>
    </ListItem>
  );
};

const SlideTasks = () => tasks.map<any, any>(
  (task, index) => <ListItemTask {...task} index={index} />,
);

const learningProgressTypes = [
  'none',
  'low',
  'medium',
  'high',
];
const getProgress = (distribution) => {
  const newDistribution = {};
  distribution.forEach((count, index) => {
    newDistribution[learningProgressTypes[index]] = count;
  });
  return newDistribution;
};
const topics = [
  {
    icon: <QuestionSet />,
    title: 'Cardiology',
    overline: '42 QUESTIONS',
    distribution: getProgress([1, 2, 5, 50]),
  },
  {
    icon: <StudySet />,
    title: 'Genetic Syndromes',
    overline: '64 FLASHCARDS',
    distribution: getProgress([17, 21, 10, 21]),
  },
  {
    icon: <StudySet />,
    title: 'Lung Anatomy',
    overline: '37 FLASHCARDS',
    distribution: getProgress([10, 2, 81, 30]),
  },
  {
    icon: <StudySet />,
    title: 'Pharmacology',
    overline: '100 FLASHCARDS',
    distribution: getProgress([0, 10, 3, 3]),
  },
];

const ListItemProgress = (progressProps) => {
  const {
    title,
    icon,
    overline,
    distribution,
    index,
  } = progressProps;
  const classes = useStylesListItem();

  return (
    <ListItem key={title} divider={index !== topics.length - 1}>
      <Box mr="12px">
        <ProgressRing
          distribution={distribution}
          variant="mastery"
          radius={24}
          innerRadius={19}
          icon={icon}
        />
      </Box>
      <ListItemText
        disableTypography
        primary={(
          <>
            <Typography variant="overline" className={classes.overline}>
              {overline}
            </Typography>
            <Typography className={classes.taskTitle} variant="h1">
              {title}
            </Typography>
          </>
        )}
      />
    </ListItem>
  );
};
const SlideProgress = () => topics.map<any, any>((topic, index) => (
  <ListItemProgress {...topic} index={index} />
));

const steps = [
  {
    label: 'Create Schedule',
    icon: <CalendarClock />,
    description: 'Your personalized study schedule powered by **spaced repetition**',
    cardContent: <SlideCreateSchedule />,
  },
  {
    label: 'Complete Tasks',
    icon: <FormatListCheckbox />,
    description: 'Complete your daily study tasks to **stay on track**',
    cardContent: <SlideTasks />,
  },
  {
    label: 'Track Progress',
    icon: <ChartTimelineVariantShimmer />,
    description: 'Track your learning progress and know exactly **where to improve**',
    cardContent: <SlideProgress />,
  },
];

export default steps;
