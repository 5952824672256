// @flow
/* -----------------Globals--------------- */
import React, { useState, useEffect } from 'react';

/* -----------------Next.js--------------- */
import Image from 'next/image';

/* -----------------Third parties--------------- */
import clsx from 'clsx';
import ReactMarkdown from 'react-markdown/with-html';

/* -----------------UI--------------- */
import {
  Container,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  StepButton,
  Avatar,
  Fade,
  withStyles,
} from '@material-ui/core';

/* -----------------Icons--------------- */
import {
  Check,
} from 'mdi-material-ui';

/* -----------------Child Components--------------- */
import Slide from './Slide';

/* -----------------Utils--------------- */
import steps from './scheduleUtils';

/* -----------------Styles--------------- */
const delay = 7500;
const styles = (theme) => ({
  active: {},
  completed: {},
  animated: {},
  alternativeLabel: {
    top: 20,
  },
  connectorRoot: {
    height: 4,
    border: 0,
    marginLeft: 2,
    backgroundColor: 'rgba(255,255,255, 0.5)',
    borderRadius: 1,
  },
  line: {
    width: 50,
    height: 4,
    border: 'none',
    // borderRadius: 1,
    '&$animated': {
      animationName: '$growConnector',
      animationDuration: `${delay}ms`,
      animationTimingFunction: theme.transitions.easing.easeInOut,
      width: '100%',
      backgroundColor: theme.palette.common.white,
    },
    '&$active': {
      backgroundColor: theme.palette.common.white,
      width: '100%',
    },
  },
  // Animation keyframes
  '@keyframes growConnector': {
    from: { width: '0%' },
    to: { width: '100%' },
  },
  customStepIconRoot: {
    color: theme.palette.common.white,
    backgroundColor: 'transparent',
    border: '2px solid white',
    '&$active': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
    },
    '&$completed': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: theme.fontSizes.h3,
    lineHeight: 1.5,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.fontSizes.h5,
    },
  },
  stepperRoot: {
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  backgroundGradient: {
    backgroundImage: 'linear-gradient(#6155DC, #124271)',
  },
  stepLabelLabel: {
    ...theme.overrides.MuiTypography.button,
    color: `${theme.palette.common.white} !important`,
    fontWeight: 400,
  },
  stepLabelActive: {
    ...theme.overrides.MuiTypography.button,
    color: `${theme.palette.common.white} !important`,
    fontWeight: 'bold !important',
  },

});

/* -----------------Type declarations--------------- */
type Classes = {
  active: string,
  completed: string,
  animated: string,
  alternativeLabel: string,
  connectorRoot: string,
  line: string,
  from: string,
  to: string,
  customStepIconRoot: string,
  title: string,
  overline: string,
  taskTitle: string,
  stepLabelLabel: string,
  stepLabelActive: string,
  stepperRoot: string,
  backgroundGradient: string
}

/* -----------------Props--------------- */
type Props = {
  classes: Classes,
  title: string,
  description: string,
};

/* -----------------Component--------------- */
const Schedule = (props: Props) => {
  const { classes, title, description } = props;

  /* -----------------Stepper functions--------------- */
  const numSteps = steps.length;

  const [activeStep, setActiveStep] = useState(0);

  const handleSelectStep = (index) => {
    setActiveStep(index);
  };

  const handleNextStep = () => {
    const newStep = (activeStep + 1) % numSteps;
    setActiveStep(newStep);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextStep();
    }, delay);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const CustomConnector = (connectorProps: any) => {
    const { index } = connectorProps;
    const filled = index <= activeStep;
    const animated = index === activeStep + 1;
    return (
      <StepConnector
        {...connectorProps}
        classes={{
          root: classes.connectorRoot,
          line: clsx(
            classes.line,
            animated && classes.animated,
            filled && classes.active,
          ),
          alternativeLabel: classes.alternativeLabel,
        }}
      />
    );
  };

  const CustomStepIcon = (iconProps) => {
    const { active, completed, icon } = iconProps;
    const customIcon = steps[icon - 1].icon;
    return (
      <Avatar
        variant="rounded"
        classes={{
          root: clsx(
            classes.customStepIconRoot,
            active && classes.active,
            completed && classes.completed,
          ),
        }}
      >
        {completed ? <Check /> : customIcon}
      </Avatar>
    );
  };

  const StepperView = () => (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      nonLinear
      connector={<CustomConnector />}
      classes={{ root: classes.stepperRoot }}
    >
      {steps.map(({ label }, index) => (
        <Step key={index.toString()}>
          <StepButton
            onClick={() => handleSelectStep(index)}
            completed={index < activeStep}
          >
            <StepLabel
              StepIconComponent={CustomStepIcon}
              classes={{
                alternativeLabel: classes.stepLabelLabel,
                active: classes.stepLabelActive,
                label: classes.stepLabelLabel,
              }}
            >
              {label}
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );

  const RenderSlide = (stepProps) => (
    <Fade in timeout={1000}>
      <Slide {...stepProps} />
    </Fade>
  );

  const AIBL = () => (
    <Box
      display="flex"
      justifyContent="center"
    >
      <Image
        src="/static/splash/aibl.png"
        alt="AIBL"
        width={360}
        height={386 / 911 * 360}
        quality={100}
        layout="fixed"
        loading="eager"
      />
    </Box>
  );

  return (
    <Box className={classes.backgroundGradient} pt={12} pb={6} color="common.white">
      <Container maxWidth="md">
        <Box textAlign="center" width="100%">
          <Typography className={classes.title} variant="h2" textAlign="center">
            {title}
          </Typography>
          <Typography variant="body1">
            <ReactMarkdown>
              {description}

            </ReactMarkdown>
          </Typography>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <StepperView />
      </Container>
      <Container maxWidth="md">
        <RenderSlide {...steps[activeStep]} />
      </Container>
      <AIBL />
    </Box>
  );
};

export default withStyles(styles)(Schedule);
