// @flow
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------UI--------------- */
import { SvgIcon } from '@material-ui/core';

const StudyPackOutline = (props: *) => (
  <SvgIcon {...props}>
    <path d="M 21.882 18.67 L 12.665 23.792 C 12.478 23.931 12.245 24 12 24 C 11.755 24 11.522 23.931 11.335 23.792 L 2.118 18.67 C 1.737 18.472 1.499 18.081 1.5 17.655 L 1.5 7.272 C 1.5 6.834 1.745 6.453 2.118 6.257 L 6.18 4 C 6.171 4.128 6.167 4.258 6.167 4.388 C 6.167 5.099 6.297 5.78 6.534 6.408 L 5.047 7.272 L 12 11.137 L 19.583 7.272 L 17.544 6.187 C 17.732 5.621 17.833 5.016 17.833 4.388 C 17.833 4.258 17.829 4.128 17.82 4 L 21.882 6.257 C 22.255 6.453 22.5 6.834 22.5 7.272 L 22.5 17.655 C 22.5 18.093 22.255 18.474 21.882 18.67 Z M 10.833 20.874 L 10.833 13.133 L 3.833 9.245 L 3.833 16.974 Z M 20.167 16.974 L 20.167 9.245 L 13.167 13.133 L 13.167 20.874 Z" />
    <path d="M 12 5.965 L 10.765 6.75 L 11.09 5.27 L 10 4.275 L 11.438 4.147 L 12 2.75 L 12.562 4.147 L 14 4.275 L 12.91 5.27 L 13.235 6.75 M 16.5 3.429 L 13.264 3.141 L 12 0 L 10.736 3.141 L 7.5 3.429 L 9.952 5.67 L 9.219 9 L 12 7.233 L 14.781 9 L 14.043 5.67 Z" />
  </SvgIcon>
);

const StudyPack = (props: *) => (
  <SvgIcon {...props}>
    <path d="M 21.882 18.67 L 12.665 23.792 C 12.478 23.931 12.245 24 12 24 C 11.755 24 11.522 23.931 11.335 23.792 L 2.118 18.67 C 1.737 18.472 1.499 18.081 1.5 17.655 L 1.5 7.272 C 1.5 6.834 1.745 6.453 2.118 6.257 L 6.18 4 C 6.171 4.128 6.167 4.258 6.167 4.388 C 6.167 5.099 6.297 5.78 6.534 6.408 L 5.047 7.272 L 12 11.137 L 19.583 7.272 L 17.544 6.187 C 17.732 5.621 17.833 5.016 17.833 4.388 C 17.833 4.258 17.829 4.128 17.82 4 L 21.882 6.257 C 22.255 6.453 22.5 6.834 22.5 7.272 L 22.5 17.655 C 22.5 18.093 22.255 18.474 21.882 18.67 Z M 10.833 20.874 L 10.833 13.133 L 3.833 9.245 L 3.833 16.974 Z" />
    <path d="M 12 7.233 L 14.781 9 L 14.043 5.67 L 16.5 3.429 L 13.264 3.136 L 12 0 L 10.736 3.136 L 7.5 3.429 L 9.953 5.67 L 9.219 9 Z" />
  </SvgIcon>
);

const StudySet = (props: *) => (
  <SvgIcon {...props}>
    <path d="M 19 20.111 L 8 20.111 L 8 6.889 L 19 6.889 M 19 5 L 8 5 C 6.895 5 6 5.846 6 6.889 L 6 20.111 C 6 21.154 6.895 22 8 22 L 19 22 C 20.105 22 21 21.154 21 20.111 L 21 6.889 C 21 5.846 20.105 5 19 5 M 17.94 10.723 L 16.88 9.722 L 12 14.331 L 9.88 12.329 L 8.82 13.33 L 12 16.333 Z" />
    <path d="M 15 3.889 L 4 3.889 L 4 17.111 L 6 17.111 L 6 19 L 4 19 C 2.895 19 2 18.154 2 17.111 L 2 3.889 C 2 2.846 2.895 2 4 2 L 15 2 C 16.105 2 17 2.846 17 3.889 L 17 4.833 L 15 4.833 Z" />
  </SvgIcon>
);

const QuestionSet = (props: *) => (
  <SvgIcon {...props}>
    <path d="M 19 3.1 L 14.82 3.1 C 14.4 1.882 13.3 1 12 1 C 10.7 1 9.6 1.882 9.18 3.1 L 5 3.1 C 3.9 3.1 3 4.045 3 5.2 L 3 19.9 C 3 21.055 3.9 22 5 22 L 19 22 C 20.1 22 21 21.055 21 19.9 L 21 5.2 C 21 4.045 20.1 3.1 19 3.1 M 12 3.1 C 12.55 3.1 13 3.572 13 4.15 C 13 4.728 12.55 5.2 12 5.2 C 11.45 5.2 11 4.727 11 4.15 C 11 3.572 11.45 3.1 12 3.1 M 7 6.5 L 17 6.5 L 17 5.2 L 19 5.2 L 19 19.9 L 5 19.9 L 5 5.2 L 7 5.2" />
    <path d="M 12.625 9.383 L 17.625 9.383 L 17.625 10.883 L 12.625 10.883 L 12.625 9.383 M 12.625 15.183 L 17.625 15.183 L 17.625 16.683 L 12.625 16.683 L 12.625 15.183 M 8.467 11.883 L 6.625 9.994 L 7.367 9.233 L 8.467 10.356 L 10.883 7.883 L 11.625 8.644 Z M 7.463 14.11 L 6.798 14.775 L 8.133 16.11 L 6.798 17.445 L 7.463 18.11 L 8.798 16.775 L 10.133 18.11 L 10.798 17.445 L 9.463 16.11 L 10.798 14.775 L 10.133 14.11 L 8.798 15.445 L 7.463 14.11" />
  </SvgIcon>
);

const Google = (props: *) => (
  <SvgIcon {...props}>
    <path d="M 23.52 12.273 C 23.52 11.421 23.444 10.604 23.301 9.819 L 12 9.819 L 12 14.46 L 18.459 14.46 C 18.189 15.944 17.323 17.252 16.064 18.081 L 16.064 21.093 L 19.941 21.093 C 22.211 19.004 23.52 15.927 23.52 12.273 Z" fill="rgb(66,133,244)" />
    <path d="M 12 24 C 15.24 24 17.956 22.925 19.941 21.093 L 16.064 18.081 C 14.989 18.801 13.615 19.228 12 19.228 C 8.875 19.228 6.229 17.116 5.285 14.28 L 1.276 14.28 L 1.276 17.389 C 3.313 21.444 7.463 24.002 12 24 Z" fill="rgb(52,168,83)" />
    <path d="M 5.285 14.28 C 5.038 13.545 4.911 12.775 4.909 12 C 4.909 11.209 5.045 10.44 5.285 9.72 L 5.285 6.611 L 1.276 6.611 C 0.436 8.283 -0.001 10.129 0 12 C 0 13.936 0.464 15.769 1.276 17.389 Z" fill="rgb(251,188,5)" />
    <path d="M 12 4.773 C 13.761 4.773 15.344 5.379 16.587 6.567 L 20.029 3.127 C 17.951 1.188 15.235 0 12 0 C 7.463 -0.002 3.313 2.556 1.276 6.611 L 5.285 9.72 C 6.229 6.884 8.875 4.773 12 4.773 Z" fill="rgb(234,67,53)" />
    <path d="M 0 0 L 24 0 L 24 24 L 0 24 Z" fill="transparent" />
  </SvgIcon>
);

export {
  QuestionSet,
  StudyPack,
  StudyPackOutline,
  StudySet,
  Google,
};
