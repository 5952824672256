// @flow

const pageType = {
  splash: 'splash',
  testimonials: 'testimonials',
  checkout: 'checkout',
  legacy: 'legacy',
  blog: 'blog',
  mission: 'mission',
  'blog post': 'blog post',
  'blog category': 'blog category',
  team: 'team',
  legal: 'legal',
  redeem: 'redeem',
  store: 'store',
  session_start: 'session_start',
  session_summary: 'session_summary',
  session_config: 'session_config',
  category: 'category',
  product: 'product',
  partners: 'partners',
  mentors: 'mentors',
  'study set': 'study set',
  'question set': 'question set',
  folder: 'folder',
  'create study set': 'create study set',
  'create question set': 'create question set',
  search: 'search',
  profile: 'profile',
  user: 'user',
  settings: 'settings',
  favorites: 'favorites',
  recents: 'recents',
  created: 'created',
  'study-packs': 'study-packs',
  tasks: 'tasks',
  shared: 'shared',
  schedule: 'schedule',
  'create schedule': 'create schedule',
  login: 'login',
  signup: 'signup',
  dashboard: 'dashboard',
  'edit schedule': 'edit schedule',
  logout: 'logout',
  progress: 'progress',
  leaderboardViewed: 'leaderboard_viewed',
};

type PageTypes = $Keys<typeof pageType>

const tagType = {
  'g-optimize': 'g-optimize',
  hotjar: 'hotjar',
};
export type {
  PageTypes,
};

export {
  pageType,
  tagType,
};
