// @flow
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------UI--------------- */
import {
  Typography,
  Card,
  Box,
  Grid,
  withStyles,
  Fade,
} from '@material-ui/core';

/* -----------------3rd-Party--------------- */
import ReactMarkdown from 'react-markdown/with-html';

/* -----------------Styles--------------- */
const styles = (theme) => ({
  boxDescription: {
    paddingRight: theme.spacing(4),
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      minHeight: 120,
      paddingRight: 0,
      paddingBottom: theme.spacing(2),
    },
  },
  description: {
    lineHeight: 1.5,
    textAlign: 'left',
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: 24,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 24,
    },
  },
  gridItemCard: {
    minHeight: 336,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      // minHeight: 311,
      paddingTop: 0,
      alignItems: 'flex-start',
    },
  },
  card: {
    width: '100%',
    maxWidth: 360,
  },
});

/* -----------------Type declarations--------------- */
type Classes = {
  backgroundGradient: string,
  boxDescription: string,
  description: string,
  gridItemCard: string,
  card: string,
}

/* -----------------Props--------------- */
type Props = {
  classes: Classes,
  description: string,
  cardContent: *,
};

/* -----------------Constants--------------- */

/* -----------------Component--------------- */
const Slide = (props: Props) => {
  const { classes, description, cardContent } = props;
  return (
    <Fade in>
      <Box color="common.white">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            container
            alignContent="center"
          >
            <Box width="100%" className={classes.boxDescription}>
              <Typography variant="h4" className={classes.description}>
                <ReactMarkdown>
                  {description}
                </ReactMarkdown>
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className={classes.gridItemCard}
          >
            <Card elevation={4} classes={{ root: classes.card }}>
              <Box px={1} py={2}>
                {cardContent}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};

export default withStyles(styles)(Slide);
