// @flow

/* -----------------Globals--------------- */
import React from 'react';

/* -----------------UI--------------- */
import {
  Box,
  Container,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* -----------------Next.js--------------- */
import Image from 'next/image';

/* -----------------Constants--------------- */
import { GOOGLE_PLAY_LINK, APP_STORE_LINK } from 'common/externalUrls';

/* -----------------Assets--------------- */
import AppStoreBadge from '@images/chrome/app_store_badge_white.svg';

/* -----------------Styles--------------- */
const hoverStyle = {
  filter: 'brightness(0.8)',
  transition: '0.2s all ease-out',
};
const styles = theme => ({
  gradient: {
    background: 'linear-gradient(0deg, rgba(28, 118, 203, 1) 0%, rgba(16, 68, 117, 1))',
    position: 'relative',
    overflowY: 'hidden',
  },
  title: {
    marginBottom: theme.spacing(4),
    lineHeight: '1.5em',
    maxWidth: 550,
    textShadow: '1px 1px #000',
    fontSize: theme.fontSizes.h3,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      margin: '0 auto',
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.fontSizes.h5,
    },
  },
  hoverStyle: {
    filter: 'brightness(0.8)',
    transition: '0.2s all ease-out',
  },
  imgAppStore: {
    height: 58,
    width: 175,
    '&:hover': {
      ...hoverStyle,
    },
  },
  imgPlayStore: {
    height: 56,
    width: 193,
    '&:hover': {
      ...hoverStyle,
    },
  },
  alignCenterSm: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  screenshot: {
    height: 600,
    marginBottom: theme.spacing(-5),
    [theme.breakpoints.down('md')]: {
      height: 450,
    },
    [theme.breakpoints.down('sm')]: {
      height: 600,
      marginBottom: theme.spacing(-12),
    },
  },
  screenshotBox: {
    [theme.breakpoints.down('sm')]: {
      '&:nth-of-type(2)': {
        display: 'none',
      },
    },
  },
  boxContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

});

/* -----------------Type declarations--------------- */
type Classes = {
  background: string,
  banner: string,
  title: string,
  swiper: string,
  gradient: string,
  boxContainer: string,
  alignCenterSm: string,
  imgAppStore: string,
  imgPlayStore: string,
  screenshot: string,
  screenshotBox: string,
}

type Screenshot = {
  type: string,
  image: *
}

/* -----------------Props--------------- */
type Props = {
  classes: Classes,
  title: string,
  screenshots: Screenshot[],
};

/* -----------------Component--------------- */
const MobileApps = (props: Props) => {
  const {
    title,
    screenshots,
    classes,
  } = props;

  // TODO: Detect device type
  // const getMobileDeviceType = () => {
  //   if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
  //     return 'iOS';
  //   }
  //   if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
  //     return 'android';
  //   }
  // };

  // TODO: Return screenshots to match device type
  // const getScreenshots = () => {
  //   const deviceType = getMobileDeviceType();
  //   if (deviceType) {
  //     return filter(screenshots, { type: deviceType });
  //   }
  //   return screenshots;
  // };

  const deviceScreenshots = screenshots;

  return (
    <Box className={classes.gradient} pt={14} pb={0} color="common.white">
      <Container maxWidth="lg">
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          className={classes.boxContainer}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            className={classes.alignCenterSm}
            pb={5}
          >
            <Typography variant="h2" className={classes.title}>
              {title}
            </Typography>
            <Box
              display="flex"
              justifyContent="flex-start"
              className={classes.alignCenterSm}
            >
              <Box mb={2}>
                <a href={APP_STORE_LINK}>
                  <img
                    className={classes.imgAppStore}
                    src={AppStoreBadge}
                    alt="App Store"
                  />
                </a>
              </Box>
              <Box mx={2} mb={2}>
                <a href={GOOGLE_PLAY_LINK}>
                  <Image
                    className={classes.imgPlayStore}
                    src="/static/chrome/google_play_badge_white.png"
                    alt="Play Store"
                    height={56}
                    width={193}
                  />
                </a>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            className={classes.alignCenterSm}
          >
            {deviceScreenshots.map(screenshot => (
              <Box
                key={screenshot.type}
                px={1}
                overflow="hidden"
                className={classes.screenshotBox}
              >
                <Image
                  key={screenshot.type}
                  src={screenshot.image}
                  height={600}
                  width={300}
                  alt="Screenshot"
                  className={classes.screenshot}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default withStyles(styles)(MobileApps);
