// @flow
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------UI--------------- */
import {
  Box,
} from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Image from 'next/image';

/* -----------------Icons--------------- */
import { Check, CheckboxBlankOutline } from 'mdi-material-ui';

/* -----------------Third parties--------------- */
import { VictoryPie } from 'victory';

/* -----------------Types--------------- */
// @ts-ignore
import type { Distribution } from 'common/types';

/* -----------------Utils--------------- */
import {
  getProgressRingData,
  getColorScale,
} from 'common/schedule/progressRingUtils';

/* -----------------Styles--------------- */
const styles = (theme: any) => ({
  icon: {
    fontSize: 40,
  },
  iconStyleLarge: {
    '& svg': {
      width: 40,
      height: 40,
    },
  },
  iconSelected: {
    color: theme.palette.common.white,
  },
  circularProgress: {
    color: theme.palette.background.paper,
  },
  contentIconImage: {
    borderRadius: 4,
  },
});

/* -----------------Type declarations--------------- */
type Classes = {
  icon: string,
  iconStyleLarge: string,
  iconSelected: string,
  circularProgress: string,
  contentIconImage: string
};

/* -----------------Props--------------- */
type Props = {
  classes: Classes,
  innerRadius: number,
  radius: number,
  hover: boolean,
  distribution: Distribution<number> | number[],
  selected?: boolean,
  variant?: 'mastery' | 'progress',
  largeIcon?: boolean,
  icon: any,
  taskComplete?: boolean,
  contentIconUrl?: string,
}

/* -----------------Component--------------- */
const ProgressRing = (props: Props) => {
  const {
    innerRadius,
    radius,
    distribution,
    selected = false,
    hover,
    classes,
    largeIcon,
    icon,
    variant = 'mastery',
    taskComplete,
    contentIconUrl,
  } = props;

  const theme = useTheme();

  const {
    palette: {
      // @ts-ignore
      progress,
      secondary: { main },
    },
  } = theme;

  const progressRingData = getProgressRingData(
    distribution,
    selected,
  );

  const progressRingColorScale = getColorScale(
    selected,
    progress,
    main,
    // @ts-ignore
    variant,
  );

  const renderSelectedIcon = () => (
    <Check className={classes.iconSelected} />
  );

  const renderOnHoverIcon = () => (
    <CheckboxBlankOutline />
  );

  const renderIcon = () => {
    if (selected) {
      return renderSelectedIcon();
    } if (hover) {
      return renderOnHoverIcon();
    }
    if (contentIconUrl) {
      return (
        <Image
          width={radius}
          height={radius}
          loading="eager"
          className={classes.contentIconImage}
          src={contentIconUrl}
          objectFit="contain"
          alt="type"
        />
      );
    }
    return (
      <Box
        display="flex"
        className={largeIcon ? classes.iconStyleLarge : undefined}
      >
        {icon}
      </Box>
    );
  };

  return (
    <Box
      position="relative"
      alignItems="center"
      justifyContent="center"
      display="flex"
    >
      <VictoryPie
        data={progressRingData}
        colorScale={progressRingColorScale}
        innerRadius={selected ? 0 : innerRadius}
        radius={radius}
        labels={[]}
        width={radius * 2}
        height={radius * 2}
        style={{
          parent: {
            width: radius * 2,
            height: radius * 2,
          },
        }}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        color={taskComplete ? 'secondary.main' : 'text.primary'}
        position="absolute"
        width="100%"
        height="100%"
        left={0}
        top={0}
      >
        {renderIcon()}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(ProgressRing);
