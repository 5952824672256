// @flow
/* -----------------Globals--------------- */
import React from 'react';
import { useRouter } from 'next/router';

/* -----------------Nextjs--------------- */
import API, { graphqlOperation } from '@aws-amplify/api';

/* -----------------Context--------------- */
import { pageVariant, PageContext } from '@components/common/PageContext';

/* -----------------Types--------------- */
import {
  type BundleDetails,
} from 'common/store/types';
import {
  pageType,
  tagType,
} from 'common/analytics/types';

/* -----------------Constants--------------- */
import { testimonials } from '@components/legacy/utils';
import {
  REVALIDATE_TIME_HR,
} from '../components/src/constants';
import { makeSearchBoxJsonLd, makeSplashOrgJsonLd } from '@components/head/jsonLdUtils';

/* -----------------Child components--------------- */
import Splash from '@components/legacy/Splash';
import { authenticated, key } from '../components/src/common/authutils';

const bundleListQuery = `
    query PremiumCarouselQuery{
            bundleList {
              bundle{
                name
                id
                numericId
                categories
                numericId
                icon{
                  url
                  id
                  name
                }
                publisher{
                  name
                  id
                }
              }
            }
        }`;

/* -----------------Types--------------- */
type Props = {
  bundleList: BundleDetails[]
}

/* -----------------Splash--------------- */
const Index = (props: Props) => {
  const {
    bundleList,
  } = props;
  const {
    setVariant,
  } = React.useContext(PageContext);
  const router = useRouter();

  const isAuthenticated = authenticated(key.loggedIn);

  React.useEffect(() => {
    if (isAuthenticated) {
      router.push('/dashboard');
    }
    if (typeof setVariant === 'function') {
      setVariant(pageVariant.static, pageType.legacy, {
        tagType: [tagType.hotjar, tagType['g-optimize']],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const splashOrgJsonLd = JSON.stringify(makeSplashOrgJsonLd(testimonials.reviews));
  const searchJsonLd = JSON.stringify(makeSearchBoxJsonLd());

  const renderSplashPage = () => (
    <>
      <script type="application/ld+json">{searchJsonLd}</script>
      <script type="application/ld+json">{splashOrgJsonLd}</script>
      <Splash
        bundleList={bundleList}
      />
    </>
  );

  return renderSplashPage();
};

export const getStaticProps = async () => {
  const result = await API.graphql(graphqlOperation(bundleListQuery));
  const bundles = result.data.bundleList;
  return {
    props: {
      bundleList: bundles,
    },
    revalidate: REVALIDATE_TIME_HR,
  };
};
export default Index;



