// @flow

/* -----------------Globals--------------- */
import React from 'react';

/* -----------------Nextjs--------------- */
import dynamic from 'next/dynamic';

/* -----------------UI--------------- */
import {
  Box,
  Container,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* -----------------Styles--------------- */
const styles = theme => ({
  background: {
    position: 'relative',
    overflowX: 'hidden',
  },
  '@keyframes affiliations': {
    '0%': { transform: 'translate3d(0, 0, 0)' },
    '100%': { transform: 'translate3d(-2500px, 0, 0)' },
  },
  banner: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    textAlign: 'center',
    backgroundImage: 'url(../static/splash/student_affiliations.png)',
    backgroundColor: theme.palette.common.white,
    backgroundRepeat: 'repeat',
    animation: '$affiliations 180s infinite',
    animationTimingFunction: 'linear',
    width: 10000,
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundRepeatX: 'repeat',
    backgroundSize: 'contain',
    opacity: 0.1,
  },
  title: {
    marginBottom: theme.spacing(6),
    fontSize: theme.fontSizes.h3,
    lineHeight: 1.5,
    fontWeight: 'bold',
    display: 'block',
    color: theme.palette.secondary.dark,
    textShadow: '1px 1px #999',
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.fontSizes.h5,
    },
  },
  swiper: {
    margin: '0 auto',
    padding: theme.spacing(8, 0, 8, 0),
    width: '100%',
    maxWidth: 'calc(100vw - 64px)',
  },
});

/* -----------------Type declarations--------------- */
type Classes = {
    background: string,
    banner: string,
    title: string,
    swiper: string,
}

/* -----------------Props--------------- */
type Props = {
    classes: Classes,
    title: string,
    reviews: *,
    classes: Classes,
};

/* -----------------Component--------------- */
const StudyPacks = (props: Props) => {
  const {
    title,
    reviews,
    classes,
  } = props;

  const TestimonialsCarousel = dynamic(
    () => import('./TestimonialsCarousel'),
    { ssr: false },
  );

  return (
    <Box className={classes.background} bgcolor="common.white" py={14} textAlign="center">
      <div className={classes.banner} />
      <Container maxWidth="lg">
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
      </Container>
      <Box width="100%" py={8} className={classes.swiper}>
        <TestimonialsCarousel
          reviews={reviews}
        />
      </Box>
    </Box>
  );
};

export default withStyles(styles)(StudyPacks);
