// @flow
/* -----------------Types--------------- */
import type {
  Distribution,
} from '../types';

type ProgessData = {
  x: string,
  y: number,
}
const getTaskDistribution = (goal: number, completed: number) => {
  const percentComplete = Math.min(1, completed / goal);
  const percentRemaining = 1 - percentComplete;
  const taskComplete = percentComplete === 1;
  const distribution = [percentComplete, percentRemaining];
  return {
    taskComplete,
    distribution,
  };
};

const getMasteryProgessData = (
  dist: Distribution<number>,
): ProgessData[] => {
  const {
    high,
    low,
    medium,
    none,
  } = dist;
  const sortedDist = {
    high,
    medium,
    low,
    none,
  };
  const tArray = Object.keys(sortedDist).map(item => ({
    x: item,
    y: dist[item],
  }));
  return tArray;
};

const getProgressRingData = (
  distribution: Distribution<number> | number[],
  selected: ?boolean,
) => {
  if (!Array.isArray(distribution)) {
    return getMasteryProgessData(distribution);
  }
  if (selected) {
    return [{ x: 'select', y: 1 }];
  }
  return [
    { x: 'select', y: distribution[0] },
    { x: 'select', y: distribution[1] },
  ];
};
const getColorScale = (
  selected: ?boolean,
  progressColors: Object,
  secondaryMain: string,
  variant?: ?"progress" | "mastery",
) => {
  const {
    high, low, none: empty, medium,
  } = progressColors;
  if (selected) {
    return [secondaryMain];
  } if (variant === 'progress') {
    return [secondaryMain, empty];
  }
  return [high, medium, low, empty];
};

export {
  getTaskDistribution,
  getProgressRingData,
  getColorScale,
};
