// @flow

/* -----------------Globals--------------- */
import React from 'react';

/* -----------------UI--------------- */
import {
  Box,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* -----------------Styles--------------- */
const styles = () => ({
  image: {
    height: 128,
  },
  title: {
    fontWeight: 600,
  },
  subtitle: {
    maxWidth: 280,
  },
});

/* -----------------Type declarations--------------- */
type Classes = {
    title: string,
    image: string,
    subtitle: string,
}

/* -----------------Props--------------- */
type Props = {
  features: *,
  classes: Classes,
};

/* -----------------Component--------------- */
const Features = (props: Props) => {
  const {
    features,
    classes,
  } = props;
  return (
    <Box textAlign="center" py={10} bgcolor="background.paper">
      <Container maxWidth="lg">
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          justifyContent="space-around"
        >
          {features.map(feature => (
            <Grid
              key={feature.title}
              item
              xs={12}
              sm={6}
              md={4}
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              direction="column"
            >
              <Box
                my={5}
                mx={1}
              >
                <img
                  src={feature.image}
                  className={classes.image}
                  width={128}
                  height={128}
                  alt={feature.title}
                />
                <Box mt={2} mb={1}>
                  <Typography className={classes.title} variant="h6" display="block">{feature.title}</Typography>
                </Box>
                <Typography variant="body2" display="block" className={classes.subtitle}>{feature.subtitle}</Typography>
              </Box>
            </Grid>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default withStyles(styles)(Features);
